import PaymentFormv2 from "../compontents/payment/PaymentFormv2";

import LicensePlateInput from "../compontents/LicensePlateInput";
import { useState } from "react";

const CustomerDashboard = () => {
  const [rego, setRego] = useState("");
  return (
    <div>
      <LicensePlateInput setRego={setRego}></LicensePlateInput>
      {rego !== "" ? <PaymentFormv2></PaymentFormv2> : ""}
    </div>
  );
};

export default CustomerDashboard;
