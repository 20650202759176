import { Popover, TextField, Button, Box } from "@mui/material";
import React, { useState } from "react";

function AddPage({ isOpen, onClose, onSave, anchorEl, columns }) {
  // Initialize an empty object for the new row
  const [newRow, setNewRow] = useState({});

  const handleChange = (columnId, value) => {
    setNewRow((prev) => ({ ...prev, [columnId]: value }));
  };
  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      sx={{ p: 2 }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {columns.map((column) => (
          <TextField
            key={column.accessor}
            label={column.Header}
            value={newRow[column.accessor] || ""}
            onChange={(e) => handleChange(column.accessor, e.target.value)}
            variant="outlined"
            fullWidth
            disabled={!column.canCreate}
          />
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSave(newRow)}
        >
          Save
        </Button>
      </Box>
    </Popover>
  );
}

export default AddPage;
