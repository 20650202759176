import usePaymentScript from "./usePaymentScript";
import { React, useEffect, useState } from "react";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { useForm } from "react-hook-form";
import Cleave from "cleave.js/react";
import "./CreditCardForm.css";
import { Button, Form, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  deletePaymentInfo,
  getCBASession,
  updatevalidateCBAToken,
  validateCBAToken,
} from "../api/cbaService";
import { useMsal } from "@azure/msal-react";

function PaymentForm() {
  const { instance } = useMsal();
  const currentAccount = instance.getActiveAccount();

  const [sessionId, setSessionId] = useState("SESSION0002720054462G2210716N18");
  const [fetchingSession, setFetchingSession] = useState(false);
  const [creatingToken, setCreatingToken] = useState(false);
  const [userHasCard, setUserHasCard] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
  });
  const scriptData = usePaymentScript(sessionId);

  useEffect(() => {
    const fetchCBASession = async () => {
      if (fetchingSession) {
        return;
      }
      try {
        setFetchingSession(true);
        const response = await getCBASession(currentAccount.username, instance);
        setSessionId(response.data.sessionId);
        if (response?.data.card) {
          updateCardDetails(response.data.card);
          setUserHasCard(true);
        }
      } catch (error) {
        console.error("Get session Failed", error);
        alert(
          "The CBA Payment Gateway has failed. Please refresh the page or notify staff."
        );
      }
    };

    if (sessionId === "") {
      fetchCBASession();
      return;
    }

    if (
      (scriptData.errors && scriptData.errors.initError) ||
      sessionId === ""
    ) {
      if (
        scriptData.errors.initError === "Form Session not found or expired." ||
        sessionId === ""
      ) {
        fetchCBASession();
      }
    }
  }, [fetchingSession, scriptData.errors, sessionId]);

  useEffect(() => {
    if (scriptData.isLoading) {
      // Optionally handle loading state
    }
    if (scriptData.errors) {
      console.error(scriptData.errors);

      // Loop through script errors and set them on the form
      Object.keys(scriptData.errors).forEach((key) => {
        setError(key, {
          type: "manual",
          message: scriptData.errors[key],
        });
      });
    }
  }, [scriptData, setError]);

  // useEffect(() => {
  //   console.log(errors);
  // }, [errors]);

  const pay = async (e) => {
    e.preventDefault();
    if (window.PaymentSession) {
      await window.PaymentSession.updateSessionFromForm("card");
      setCreatingToken(false);
    }
  };

  // Update form fields based on the response data
  const updateCardDetails = (card) => {
    // console.log(card);
    if (card) {
      setValue("cvc", card.securityCode);
      if (typeof card?.expiry === "string") {
        setValue(
          "expiry",
          card.expiry.slice(0, 2) + "/" + card.expiry.slice(2)
        );
      } else {
        setValue(
          "expiry",
          `${card.expiry.month}/${card.expiry.year.toString().slice(-2)}`
        );
      }
      if (!card.nameOnCard) {
        setValue("name", "***************");
      } else {
        setValue("name", card.nameOnCard);
      }
      setValue("number", card.number);
    }
  };

  const [alertShown, setAlertShown] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const values = scriptData?.responseData?.sourceOfFunds?.provided?.card;
      if (values) {
        if (
          values.nameOnCard &&
          values.number &&
          values.expiry.month &&
          values.expiry.year &&
          values.securityCode &&
          !creatingToken
        ) {
          let response;
          try {
            setCreatingToken(true);
            if(values.brand !== "MASTERCARD" && values.brand !== "VISA"){
              if (!alertShown) {
                alert(`${values.brand} is not supported`);
                setAlertShown(true);
              }
              setCreatingToken(false);
              return;
            } else {
              setAlertShown(false); // Reset for next validation
            }

            if (userHasCard) {
              response = await updatevalidateCBAToken(
                sessionId,
                currentAccount.username,
                instance
              );
            } else {
              response = await validateCBAToken(
                sessionId,
                currentAccount.username,
                instance
              );
            }
            // console.log(response);
            if (!response.data.success) {
              alert("Failed to update card details");
              return;
            }
            updateCardDetails(values);
            setUserHasCard(true);
            console.log("GOOD CARD DETAILS");
          } catch (error) {
            console.error("Error updating card details:", error);
            // Handle errors appropriately
          }
        }
      }
    };

    fetchData();
  }, [creatingToken, currentAccount, scriptData, sessionId]);

  async function deleteToken() {
    toggleModal();
    try {
      const response = await deletePaymentInfo(
        currentAccount.username,
        instance
      );
      if (response.data.success) {
        // console.log("test");
        alert(response.data.info);
        setUserHasCard(false);
        clearCardDetails();
        return;
      }
      console.error(response.data.info);
      alert(response.data.info);
    } catch (error) {
      console.error("Deleting payment info failed:", error);
      alert(error);
    }
  }
  const toggleModal = () => setShowDeleteModal(!showDeleteModal);

  function clearCardDetails() {
    setValue("cvc", "");
    setValue("expiry", "");
    setValue("name", "");
    setValue("number", "");
  }

  return (
    <div>
      <div id="PaymentForm" className="container">
        <div className="box justify-content-center align-items-center">
          <div className="formDiv">
            {/* <style id="antiClickjack">{"body { display: none !important; }"}</style> */}
            <div>Please enter your payment details:</div>
            <div>Please note only Mastercard and Visa is supported</div>
            <div>
              <div className="creditCard">
                <Cards
                  cvc={watch("cvc") || ""}
                  expiry={watch("expiry") || ""}
                  name={watch("name") || ""}
                  number={watch("number") || ""}
                  // focused={focus}
                />
              </div>
              <Form
                // onSubmit={handleSubmit(onSubmit)}
                onSubmit={(e) => {
                  pay(e);
                }}
              >
                <Form.Group>
                  <Cleave
                    placeholder="Card Number"
                    options={{ creditCard: true }}
                    // onFocus={() => setFocus("number")}
                    className="form-control"
                    id="card-number"
                    readOnly
                  />
                  <p>{errors.cardNumber?.message}</p>
                </Form.Group>
                <Form.Group>
                  <input
                    // {...register("name", { required: "Name is required" })}
                    placeholder="Name"
                    // onFocus={() => setFocus("name")}
                    className="form-control"
                    id="cardholder-name"
                    readOnly
                  />
                  <p>{errors.name?.message}</p>
                </Form.Group>

                <Cleave
                  placeholder="Expiry (MM)"
                  options={{ date: true, datePattern: ["m"] }}
                  // onChange={handleExpiryChange}
                  // onFocus={() => setFocus("expiry")}
                  className="form-control"
                  id="expiry-month"
                  readOnly
                />
                <p>{errors.expiryMonth?.message}</p>
                <Cleave
                  placeholder="Expiry (YY)"
                  options={{ date: true, datePattern: ["y"] }}
                  // onChange={handleExpiryChange}
                  // onFocus={() => setFocus("expiry")}
                  className="form-control"
                  id="expiry-year"
                  readOnly
                />
                <p>{errors.expiryYear?.message}</p>
                <Cleave
                  placeholder="CVC"
                  options={{
                    blocks: [4],
                    numericOnly: true,
                  }}
                  // onChange={(e) => handleCVVChange(e)}
                  // onFocus={() => setFocus("cvc")}
                  className="form-control"
                  id="security-code"
                  readOnly
                />
                <p>{errors.securityCode?.message}</p>
                <Button
                  size={"block"}
                  type="submit"
                  // disabled={!isValid || Object.keys(errors).length > 0}
                  className="w-100"
                  id="payButton"
                  // onClick={pay}
                >
                  Submit
                </Button>
              </Form>
              {userHasCard && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                    height: "50px",
                  }}
                >
                  <Button variant="danger" onClick={toggleModal}>
                    Delete Card Details
                  </Button>
                </div>
              )}
            </div>
            <Modal show={showDeleteModal} onHide={toggleModal}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete your card details?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={deleteToken}>
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentForm;
