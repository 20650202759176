import React, { useState } from "react";
import { Button } from "react-bootstrap";
// import callApiWithRetry from "../compontents/callApiWithRetry";
import MyTable from "../Table/MyTable";
import {
  cancelTransaction,
  getVehicleTransactionsByRegistration,
  processTransaction,
  refundTransaction,
  updateTransaction,
} from "../api/transactionService";
import { useMsal } from "@azure/msal-react";

const TransactionTable = () => {
  const [transactionData, setTransactionData] = useState([]);
  const [transactionSearchTerm, setTransactionSearchTerm] = useState("");
  const { instance } = useMsal();

  const transactionColumns = React.useMemo(
    () => [
      {
        Header: "Transaction Number",
        accessor: "transactionNumber",
      },
      {
        Header: "Entry Time",
        accessor: "entryTime",
      },
      {
        Header: "Exit Time",
        accessor: "exitTime",
      },
      {
        Header: "Transaction Status",
        accessor: "transactionStatus",
      },
      {
        Header: "Registration Number",
        accessor: "registrationNumber",
      },
      {
        Header: "Payment Amount",
        accessor: "paymentAmount",
      },
      {
        Header: "Info",
        accessor: "info",
        editable: true,
      },
    ],
    []
  );

  const transactionHandleSearch = async () => {
    try {
      const response = await getVehicleTransactionsByRegistration(
        transactionSearchTerm,
        instance
      );
      if (!response.data.success) {
        alert(response.data.info);
        return;
      }
      // console.log(!response?.data?.vehicleTransactionListModel);
      // console.log(response.data.vehicleTransactionListModel);
      if (response?.data?.vehicleTransactionListModel.length === 0) {
        alert("No Transaction found");
      }
      setTransactionData(response.data.vehicleTransactionListModel);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Failed transaction search", error);
    }
  };

  const transactionHandleRefund = async (id) => {
    try {
      const response = await refundTransaction(id, instance);
      if (!response.data.success) {
        alert(response.data.info);
        return;
      }
      refetchTable();
      //TODO:Finish Refund
    } catch (error) {
      console.error("Error refunding:", error);
      alert("Failed refund ", error);
    }
  };

  async function refetchTable() {
    const response = await getVehicleTransactionsByRegistration(
      transactionSearchTerm,
      instance
    );

    setTransactionData(response.data.vehicleTransactionListModel);
  }

  const transactionHandleCancelPayment = async (id) => {
    try {
      const response = await cancelTransaction(id, "Cancelled", instance);
      if (!response.data.success) {
        alert(response.data.info);
        return;
      }
      refetchTable();
      //TODO:Finish Cancel tran
    } catch (error) {
      console.error("Error canceling:", error);
      alert("Failed canceling ", error);
    }
  };

  const transactionManuelPayment = async (id) => {
    try {
      const response = await cancelTransaction(id, "Paid", instance);
      if (!response.data.success) {
        alert(response.data.info);
        return;
      }
      refetchTable();
      //TODO:Finish Cancel tran
    } catch (error) {
      console.error("Error canceling:", error);
      alert("Failed canceling ", error);
    }
  };

  const transactionHandleProcessPayment = async (id) => {
    try {
      const response = await processTransaction(id, instance);
      if (!response.data.success) {
        alert(response.data.info);
        return;
      }
      refetchTable();
      //TODO:Finish process tran
    } catch (error) {
      console.error("Error canceling:", error);
      alert("Failed canceling ", error);
    }
  };

  const transactionActions = (row) => {
    return (
      <>
        {row.transactionStatus === "Paid" && (
          <Button
            onClick={() => transactionHandleRefund(row.transactionNumber)}
          >
            Refund
          </Button>
        )}
        {row.transactionStatus === "Pending" && (
          <div>
            <Button
              onClick={() =>
                transactionHandleCancelPayment(row.transactionNumber)
              }
            >
              Cancel Payment
            </Button>
            <Button
              onClick={() => transactionManuelPayment(row.transactionNumber)}
            >
              Manual Payment
            </Button>
          </div>
        )}
        {row.transactionStatus === "Failed" && (
          <div>
            <Button
              onClick={() => transactionManuelPayment(row.transactionNumber)}
            >
              Manuel Payment
            </Button>
            <Button
              onClick={() =>
                transactionHandleProcessPayment(row.transactionNumber)
              }
            >
              Process Payment
            </Button>
          </div>
        )}
      </>
    );
  };
  const config = {
    rowActions: transactionActions,
    onUpdate: async (row) => {
      try {
        // console.log(row);
        await updateTransaction(
          row.transactionNumber,
          row.transactionStatus,
          row.info,
          instance
        );
        setTransactionData((prevData) =>
          prevData.map((item) =>
            item.transactionNumber === row.transactionNumber
              ? { ...item, ...row }
              : item
          )
        );
        alert(`Transaction: ${row.transactionNumber} updated`);
      } catch (error) {
        alert("Failed to update vehicle", error);
      }
    },
  };
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: "20%" }}>
          <input
            type="text"
            className="form-control"
            value={transactionSearchTerm}
            onChange={(e) => setTransactionSearchTerm(e.target.value)}
            placeholder="Search Transaction with Rego..."
          />
        </div>
        <div style={{ width: "10%", marginLeft: "1px" }}>
          <Button onClick={transactionHandleSearch}>Search</Button>
        </div>
      </div>

      <MyTable
        data={transactionData}
        columns={transactionColumns}
        config={config}
      />
    </div>
  );
};

export default TransactionTable;
