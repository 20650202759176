import React from "react";
import "./Banner.css"; // Importing the CSS file
import { useLocation } from "react-router-dom";

const Banner = () => {
  const location = useLocation();

  return (
    <div className="et_pb_section et_pb_section_0 et_pb_with_background et_pb_fullwidth_section et_section_regular section_has_divider et_pb_bottom_divider">
      <section className="et_pb_module et_pb_fullwidth_header et_pb_fullwidth_header_0 et_pb_text_align_left et_pb_bg_layout_dark">
        <div className="et_pb_fullwidth_header_container left">
          <div className="header-content-container center">
            <div className="header-content">
              <span className="et_pb_fullwidth_header_subhead">
                {location.pathname === "/staff"
                  ? "Staff Dashboard"
                  : "Customer Dashboard"}
              </span>
              <div className="et_pb_header_content_wrapper"></div>
            </div>
          </div>
        </div>
        <div className="et_pb_fullwidth_header_overlay"></div>
        <div className="et_pb_fullwidth_header_scroll"></div>
      </section>
      <div className="et_pb_bottom_inside_divider"></div>
    </div>
  );
};

export default Banner;
