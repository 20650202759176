// import CustomTable from "./CustomTable";
import React, { useState, useEffect } from "react";
import { string } from "yup";
import MyTable from "../Table/MyTable";
import {
  createVehicleDetails,
  deleteVehicle,
  getAllVehicleDetails,
  updateVehicleDetails,
} from "../api/vehicleService";
import { useMsal } from "@azure/msal-react";

const VehicleTable = () => {
  const [vehicleData, setVehicleData] = useState([]);
  const { instance } = useMsal();

  // const editableColumnsConfig = {
  //   emailAddress: {
  //     type: "input", // 'input' for regular text input
  //     //   options: ["Active", "Inactive", "Maintenance"], // Dropdown options
  //     onSave: async (rowIndex, columnId, newValue) => {
  //       const emailUpdated = await handleEmailChange(
  //         newValue,
  //         vehicleData[rowIndex].registrationNumber,
  //         vehicleData[rowIndex].vehicleStatus
  //       );
  //       console.log(emailUpdated);
  //       if (!emailUpdated) {
  //         return;
  //       }
  //       setVehicleData((currentData) => {
  //         // Clone the current data array
  //         const updatedData = [...currentData];

  //         // Update the specific item
  //         if (updatedData[rowIndex] && columnId in updatedData[rowIndex]) {
  //           updatedData[rowIndex] = {
  //             ...updatedData[rowIndex],
  //             [columnId]: newValue, // Update the specific field
  //           };
  //         }

  //         return updatedData;
  //       });
  //     },
  //   },
  //   // You can add more editable columns here with similar configurations
  // };
  // const checkVehicleIsNotClaimed = async (email, rego) => {
  //   const url = `${process.env.REACT_APP_FUNCTION_URL}/GetVehiclesAll`;
  //   const options = {
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-functions-key": `${process.env.REACT_APP_X_KEY}`,
  //     },
  //   };
  //   try {
  //     const response = await callApiWithRetry(url, options, 3, 1000);
  //     if (response.data.success) {
  //       const isEmailValid = response.data.vehicleModelList.every((vehicle) => {
  //         // Check if registration numbers match
  //         if (rego === vehicle.registrationNumber) {
  //           return true; // If registration numbers match, the email check is bypassed
  //         }

  //         // Check if email addresses are different
  //         return email !== vehicle.emailAddress || vehicle.emailAddress === "";
  //       });
  //       if (!isEmailValid) {
  //         alert("This email is already in use");
  //         return false;
  //       }
  //       return true;
  //       // Handle success (e.g., show success message)
  //     } else {
  //       alert(response.data.info);
  //       return false;
  //     }
  //   } catch (err) {
  //     alert("Failed");
  //     console.error(err);
  //     return false;
  //   }
  // };

  // const handleEmailChange = async (email, rego, vehicleStatus) => {
  //   const vehicleNotClaimed = await checkVehicleIsNotClaimed(email, rego);
  //   if (!vehicleNotClaimed) {
  //     return false;
  //   }
  //   const url = `${process.env.REACT_APP_FUNCTION_URL}/PutVehicle`;
  //   const options = {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-functions-key": `${process.env.REACT_APP_X_KEY}`,
  //     },
  //     data: JSON.stringify({
  //       registrationNumber: rego,
  //       emailAddress: email,
  //       vehicleStatus: vehicleStatus,
  //     }),
  //   };
  //   try {
  //     const response = await callApiWithRetry(url, options, 3, 1000);
  //     if (response.data.success) {
  //       alert("Email has been updated.");
  //       return true;
  //       // Handle success (e.g., show success message)
  //     }
  //   } catch (err) {
  //     alert("Failed to save the email");
  //     console.error(err);
  //     return false;
  //   }
  // };

  const vehicleColumns = React.useMemo(
    () => [
      {
        Header: "Registration Number",
        accessor: "registrationNumber",
        canCreate: true,
        validationSchema: string().required(),
      },
      {
        Header: "Email Address",
        accessor: "emailAddress",
        editable: true,
        canCreate: true,
        validationSchema: string().email(),
      },
      {
        Header: "Vehicle Type",
        accessor: "vehicleType",
      },
      {
        Header: "Body Type",
        accessor: "bodyType",
        editable: true,
      },
      {
        Header: "Vehicle Status",
        accessor: "vehicleStatus",
      },
    ],
    []
  );

  useEffect(() => {
    // Fetch data on component mount
    const getAllVehicles = async () => {
      try {
        const response = await getAllVehicleDetails(instance);
        if (response.data.success) {
          setVehicleData(response.data.vehicleModelList);
        }
      } catch (err) {
        console.error(err);
      }
    };
    // console.log({
    //   instance: instance,
    //   account: instance.getActiveAccount(),
    // });
    getAllVehicles();
  }, [instance]);

  const config = {
    onUpdate: async (row) => {
      try {
        // console.log(row);
        const response = await updateVehicleDetails(
          row.registrationNumber,
          row.emailAddress,
          row.vehicleStatus,
          row.bodyType,
          instance
        );
        if (!response.data.success) {
          if (
            response.data.info ===
            "Error: Error setting value to 'bodyType' on 'GHF.DB.Models.VehicleModel'."
          ) {
            alert("Unknown body type set");
            return;
          }
          alert(response.data.info);
          return;
        }
        setVehicleData((prevData) =>
          prevData.map((item) =>
            item.registrationNumber === row.registrationNumber
              ? { ...item, ...row }
              : item
          )
        );
        alert(`Vehicle: ${row.registrationNumber} updated`);
      } catch (error) {
        alert("Failed to update vehicle", error);
      }
    },
    onCreate: async (row) => {
      try {
        if (regoInUse(row.registrationNumber)) {
          alert("Rego is already in use");
          return;
        }
        // console.log(row);
        // console.log(vehicleData);
        // const vehicle = vehicleData.find(
        //   (v) => v.registrationNumber === row.registrationNumber
        // );
        const response = await createVehicleDetails(
          row.registrationNumber,
          row.emailAddress,
          "",
          instance
        );
        if (!response.data.success) {
          alert(response.data.info);
          return;
        }
        // console.log(row);
        setVehicleData((prevData) => [
          ...prevData,
          {
            registrationNumber: row.registrationNumber,
            emailAddress: row.emailAddress,
            vehicleType: "",
            bodyType: "",
            vehicleStatus: "",
          },
        ]);
        alert(`Config ${row.registrationNumber} created`);
      } catch (error) {
        console.error(error);
        alert("Failed to create new vehicle", error);
      }
    },
    onDelete: async (values) => {
      try {
        // console.log(values);
        const response = await deleteVehicle(
          values.registrationNumber,
          instance
        );
        if (!response.data.success) {
          alert(response.data.info);
          return;
        }
        setVehicleData((prevConfigData) =>
          prevConfigData.filter(
            (currentRow) =>
              currentRow.registrationNumber !== values.registrationNumber
          )
        );
        alert(`Vehicle ${values.registrationNumber} deleted`);
      } catch (error) {
        alert("Failed to delete vehicle", error);
      }
    },
  };
  function regoInUse(rego) {
    return vehicleData.some(
      (currentRow) => currentRow.registrationNumber === rego
    );
  }
  const [filters, setFilters] = useState({});

  return (
    <MyTable
      data={vehicleData}
      columns={vehicleColumns}
      config={config}
      filters={filters}
      setFilters={setFilters}
    />
  );
};

export default VehicleTable;
