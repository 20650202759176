// import { React, useEffect } from "react";
import "./App.css";
import { MsalProvider } from "@azure/msal-react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./compontents/ProtectedRoute";
import StaffDashboard from "./pages/StaffDashboard";
import CustomerDashboard from "./pages/CustomerDashboard";
import { PageLayout } from "./pages/PageLayout";
import { appRoles } from "./compontents/azure/authConfig";
import "./styles/App.css";
import NotFoundPage from "./pages/NotFoundPage";

function App({ instance }) {
  const Pages = () => {
    return (
      <Routes>
        <Route
          exact
          path="/staff"
          element={
            <ProtectedRoute roles={appRoles.StaffUser}>
              <StaffDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/"
          element={
            <ProtectedRoute roles={appRoles.CustomerUser}>
              <CustomerDashboard />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  };

  return (
    <MsalProvider instance={instance}>
      <PageLayout>
        <Pages instance={instance} />
      </PageLayout>
    </MsalProvider>
  );
}

export default App;
