import { apiRequest } from "./apiUtils";

// Function to get vehicle details
export async function getVehicleDetailsWithPlate(plateNumber, instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/GetVehicle?registrationNumber=${plateNumber}`;
  return await apiRequest(url, "GET", instance);
}

export async function getVehicleDetailsWithEmail(email, instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/GetVehicleByEmail?emailAddress=${email}`;
  return await apiRequest(url, "GET", instance);
}

// Function to update vehicle details
export async function updateVehicleDetails(
  plateNumber,
  email,
  status,
  bodyType = null,
  instance
) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/PutVehicle`;
  const data = {
    registrationNumber: plateNumber,
    emailAddress: email,
    vehicleStatus: status,
    bodyType: bodyType,
  };
  return await apiRequest(url, "PUT", instance, data);
}

export async function updateVehicleDetailsRego(
  plateNumber,
  email,
  status,
  instance
) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/PutVehicle`;
  const data = {
    registrationNumber: plateNumber,
    emailAddress: email,
    vehicleStatus: status,
  };
  return await apiRequest(url, "PUT", instance, data);
}

// Function to create vehicle details
export async function createVehicleDetails(
  plateNumber,
  email,
  status,
  instance
) {
  const data = {
    registrationNumber: plateNumber,
    emailAddress: email,
    vehicleStatus: status,
  };
  const url = `${process.env.REACT_APP_FUNCTION_URL}/PostVehicle`;
  return await apiRequest(url, "POST", instance, data);
}

// Function to get all vehicle details
export async function getAllVehicleDetails(instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/GetVehiclesAll`;
  return await apiRequest(url, "GET", instance);
}

// Function to create config details
export async function deleteVehicle(rego, instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/DeleteVehicle?registrationNumber=${rego}`;
  return await apiRequest(url, "DELETE", instance);
}
