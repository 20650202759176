import { apiRequest } from "./apiUtils";

// Function to update config details
export async function updateConfig(type, cost, instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/PutCostLookup`;
  const data = {
    bodyType: type,
    cost: cost,
  };
  // console.log(data);
  return await apiRequest(url, "PUT", instance, data);
}

// Function to create config details
export async function createConfig(type, cost, instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/PostCostLookup`;
  const data = {
    bodyType: type,
    cost: cost,
  };
  // console.log(data);
  return await apiRequest(url, "POST", instance, data);
}

// Function to create config details
export async function deleteConfig(type, instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/DeleteCostLookup?bodyType=${type}`;
  return await apiRequest(url, "DELETE", instance);
}

export async function getAllConfigs(instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/GetCostLookupAll`;
  return await apiRequest(url, "GET", instance);
}
