import { apiRequest } from "./apiUtils";

export async function getVehicleTransactionsByRegistration(
  plateNumber,
  instance
) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/GetVehicleTransactionsByRegistration?registrationNumber=${plateNumber}`;
  return await apiRequest(url, "GET", instance);
}

export async function refundTransaction(transactionId, instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/PostVehicleTransactionRefund`;
  const body = {
    transactionNumber: transactionId,
  };
  return await apiRequest(url, "POST", instance, body);
}

export async function updateTransaction(transactionId, status, info, instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/PutVehicleTransaction`;
  const body = {
    transactionNumber: transactionId,
    transactionStatus: status,
    info: info,
  };
  return await apiRequest(url, "PUT", instance, body);
}

export async function cancelTransaction(transactionId, status, instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/PutVehicleTransaction`;
  const body = {
    transactionNumber: transactionId,
    transactionStatus: status,
  };
  return await apiRequest(url, "PUT", instance, body);
}

export async function processTransaction(transactionId, instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/PostVehicleTransactionPay`;
  const body = {
    transactionNumber: transactionId,
  };
  return await apiRequest(url, "POST", instance, body);
}
