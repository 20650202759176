import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Navbar, Button } from "react-bootstrap";
import logo from "../assets/logo.png";

import { loginRequest } from "../compontents/azure/authConfig";

export const NavigationBar = () => {
  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
  };

  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */
  return (
    <>
      <Navbar
        // bg="66cc33" variant="dark"
        className="navbarStyle"
        style={{
          backgroundColor: "#FFFFFF",
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 1030, // Ensures the navbar stays on top of other elements
          borderBottom: "1px solid #ccc",
        }}
      >
        <a className="navbar-brand" href="/">
          <img
            src={logo}
            alt="Logo"
            style={{ maxHeight: "50px", width: "auto" }}
          />
        </a>
        <AuthenticatedTemplate>
          <div className="collapse navbar-collapse justify-content-end">
            <Button
              variant="warning"
              onClick={handleLogoutRedirect}
              style={{ marginRight: "10px" }}
            >
              Logout
            </Button>
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div className="collapse navbar-collapse justify-content-end">
            <Button
              variant="warning"
              onClick={handleLoginRedirect}
              style={{ marginRight: "10px" }}
            >
              Login
            </Button>
          </div>
        </UnauthenticatedTemplate>
      </Navbar>
    </>
  );
};
