import React from "react";
import TransactionTable from "../compontents/staffTablesv2/transactionTable";

import VehicleTable from "../compontents/staffTablesv2/vehicleTable";

import ConfigTable from "../compontents/staffTablesv2/configTable";

const StaffDashboard = () => {
  return (
    <div className="App">
      <ConfigTable></ConfigTable>
      <VehicleTable></VehicleTable>
      <TransactionTable></TransactionTable>
    </div>
  );
};

export default StaffDashboard;
