import React, { useState, useEffect, useMemo } from "react";
import {
  useTable,
  usePagination,
  useRowSelect,
  useColumnOrder,
  useGlobalFilter,
  useFilters,
  useSortBy,
} from "react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TablePagination,
  TextField,
  TableSortLabel,
} from "@mui/material";
import TableToolbar from "./TableToolbar";
import EditPage from "./editPage";
import { object } from "yup";
import AddPage from "./addPage";
import { fuzzyTextFilter } from "./filters/fuzzyFilter";

const findFirstColumn = (columns) =>
  columns[0].columns ? findFirstColumn(columns[0].columns) : columns[0];

function DefaultColumnFilter({ columns, column, gotoPage }) {
  const { id, filterValue, setFilter, render } = column;
  const [value, setValue] = React.useState(filterValue || "");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  // ensure that reset loads the new value
  useEffect(() => {
    setValue(filterValue || "");
  }, [filterValue]);

  const isFirstColumn = findFirstColumn(columns) === column;
  return (
    <TextField
      name={id}
      label={render("Header")}
      InputLabelProps={{ htmlFor: id }}
      value={value}
      autoFocus={isFirstColumn}
      variant="standard"
      onChange={handleChange}
      onBlur={(e) => {
        const value = e.target.value || undefined;
        setFilter(value);
        if (value !== filterValue) gotoPage(0);
      }}
    />
  );
}

const MyTable = ({ columns, data, config }) => {
  const [isEditPopoverOpen, setIsEditPopoverOpen] = useState(false);
  const [isCreatePopoverOpen, setIsCreatePopoverOpen] = useState(false);
  const [onAddInUse, setOnAddInUse] = useState(false);
  const [onUpdateInUse, setOnUpdateInUse] = useState(false);
  const [onDeleteInUse, setOnDeleteInUse] = useState(false);

  const filterTypes = {
    fuzzyText: fuzzyTextFilter,
    // numeric: numericTextFilter,
  };

  const hooks = [
    useColumnOrder,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
  ].filter(notEmpty);

  const defaultColumn = useMemo(
    () => ({
      // disableFilter: true,
      // disableGroupBy: true,
      Filter: DefaultColumnFilter,
      // Cell: TooltipCellRenderer,
      // Header: DefaultHeader,
      aggregate: "uniqueCount",
      Aggregated: ({ cell: { value } }) => <>{value} Unique Values</>,
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const instance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      filterTypes,
      defaultColumn,
    },
    ...hooks,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "_selector",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
        },
        ...columns,
      ]);
    }
  );

  function notEmpty(value) {
    return value !== null && value !== undefined;
  }

  // Destructure properties from the table instance
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    selectedFlatRows,
    state: { pageIndex, pageSize },
    setPageSize,
    gotoPage,
  } = instance;
  // console.log(instance);
  const handleAdd = () => {
    return (event) => {
      if (selectedFlatRows.length !== 0) {
        setIsCreatePopoverOpen(false);
        setOnAddInUse(false);
        return;
      }
      setOnAddInUse(true);
      setCreateAnchorEl(event.currentTarget);
      setIsCreatePopoverOpen(true);
    };
  };

  const handleEdit = () => {
    return (event) => {
      if (selectedFlatRows.length !== 1) {
        setIsEditPopoverOpen(false);
        setOnUpdateInUse(false);
        return;
      }
      setOnUpdateInUse(true);
      setEditAnchorEl(event.currentTarget);
      setIsEditPopoverOpen(true);
    };
  };

  const handleDelete = () => {
    // Logic for deleting selected rows
    return async (event) => {
      // Guard clause for the number of selected rows
      setOnDeleteInUse(true);
      if (selectedFlatRows.length !== 1) {
        setOnDeleteInUse(false);
        return;
      }

      // Extract vehicleType using optional chaining
      const values = selectedFlatRows[0]?.values;

      // Guard clause for existence of vehicleType
      if (!values) {
        setOnDeleteInUse(false);
        return;
      }
      await config.onDelete(values);
      setOnDeleteInUse(false);
    };
  };
  const [editAnchorEl, setEditAnchorEl] = useState(undefined);
  const [createAnchorEl, setCreateAnchorEl] = useState(undefined);

  // Render the UI for your table
  return (
    <>
      <TableToolbar
        instance={instance}
        {...(config.onCreate && { onAdd: handleAdd })}
        {...(config.onUpdate && { onEdit: handleEdit })}
        {...(config.onDelete && { onDelete: handleDelete })}
        onAddInUse={onAddInUse}
        onUpdateInUse={onUpdateInUse}
        onDeleteInUse={onDeleteInUse}
      />
      <EditPage
        anchorEl={editAnchorEl}
        row={selectedFlatRows.length === 1 ? selectedFlatRows[0] : {}}
        isOpen={isEditPopoverOpen}
        onClose={() => {
          setIsEditPopoverOpen(false);
          setOnUpdateInUse(false);
        }}
        onSave={async (updatedRow) => {
          try {
            const validationSchema = object().shape(
              columns.reduce((acc, column) => {
                if (column.validationSchema) {
                  acc[column.accessor] = column.validationSchema;
                }
                return acc;
              }, {})
            );

            await validationSchema.validate(updatedRow, { abortEarly: false });
            // console.log(validationSchema);
            setOnUpdateInUse(false);
            await config.onUpdate(updatedRow);
            setIsEditPopoverOpen(false);
            // Proceed with saving data if validation passes
          } catch (error) {
            // Handle validation errors
            alert(error);
            console.error(error);
            setIsEditPopoverOpen(false);
            setOnUpdateInUse(false);
          }
        }}
      />
      <AddPage
        anchorEl={createAnchorEl}
        isOpen={isCreatePopoverOpen}
        onClose={() => {
          setIsCreatePopoverOpen(false);
          setOnAddInUse(false);
        }}
        columns={columns}
        onSave={async (updatedRow) => {
          try {
            const validationSchema = object().shape(
              columns.reduce((acc, column) => {
                if (column.validationSchema) {
                  acc[column.accessor] = column.validationSchema;
                }
                return acc;
              }, {})
            );

            await validationSchema.validate(updatedRow, { abortEarly: false });
            // console.log(validationSchema);
            setOnAddInUse(false);
            await config.onCreate(updatedRow);
            setIsCreatePopoverOpen(false);
          } catch (error) {
            // Handle validation errors
            setOnAddInUse(false);
            setIsCreatePopoverOpen(false);
            alert(error);
            console.error(error);
          }
        }}
      />
      <TableContainer component={Paper}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const headerProps = column.getHeaderProps(
                    column.getSortByToggleProps()
                  );

                  return (
                    <TableCell {...headerProps}>
                      {column.canSort ? (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        >
                          {column.render("Header")}
                        </TableSortLabel>
                      ) : (
                        column.render("Header")
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                  {config?.rowActions && (
                    <td>{config?.rowActions(row.original)}</td>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.length} // Total number of rows in your data
        rowsPerPage={pageSize} // Number of rows per page
        page={pageIndex} // Current page index
        onPageChange={(event, newPage) => gotoPage(newPage)} // Handler for page change
        onRowsPerPageChange={(event) => {
          setPageSize(Number(event.target.value)); // Handler for rows per page change
        }}
      />
    </>
  );
};

export default MyTable;
