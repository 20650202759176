import { AuthenticatedTemplate } from "@azure/msal-react";

import { NavigationBar } from "./NavigationBar";
import Banner from "../compontents/banner/banner";
export const PageLayout = (props) => {
  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */

  return (
    <>
      <NavigationBar style={{ paddingTop: "60px" }} />
      <Banner></Banner>
      <div
        style={{
          paddingTop: "60px",
          borderLeft: "20px solid #FFFFFF",
          borderRight: "20px solid #FFFFFF",
        }}
      >
        {props.children}
      </div>

      <AuthenticatedTemplate></AuthenticatedTemplate>
    </>
  );
};
