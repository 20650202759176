// apiUtils.js
import axios from "axios";
import { loginRequest } from "../azure/authConfig";
const getAccessToken = async (instance) => {
  let account = instance.getActiveAccount();

  if (!account) {
    account = instance.getActiveAccount();
    if (!account) {
      console.log("No active account! Verify a user is signed in.");
      return null;
    }
  }

  const silentRequest = {
    ...loginRequest,
    account: account,
  };

  try {
    const response = await instance.acquireTokenSilent(silentRequest);
    return response.accessToken; // Return the access token
  } catch (error) {
    console.error("Failed to acquire token silently. Error:", error);
    // Optionally, handle interaction required scenarios
    return null;
  }
};

/**
 * Makes an API call using callApiWithRetry for handling retries.
 *
 * @param {string} url - The URL of the API endpoint.
 * @param {string} method - The HTTP method to use for the request (e.g., 'GET', 'POST', 'PUT').
 * @param {object|null} body - The body of the request. If present, it is stringified before sending.
 * @param {number} retries - The maximum number of retry attempts in case of failure.
 * @param {number} delay - The delay in milliseconds between retry attempts.
 *
 * @returns {Promise} - A Promise that resolves to the response of the API call.
 */
export async function apiRequest(
  url,
  method,
  instance,
  body = null,
  retries = 3,
  delay = 3000
) {
  let accessToken;

  try {
    accessToken = await getAccessToken(instance);

    if (!accessToken) {
      throw new Error("Failed to retrieve access token.");
    }
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }

  let bodyData = body ? JSON.stringify(body) : null;

  const options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`, // Add the token to the Authorization header
      "x-functions-key": process.env.REACT_APP_X_KEY,
    },
    data: bodyData,
  };

  return await callApiWithRetry(url, options, retries, delay);
}

/**
 * Makes an API call with retry logic.
 * @param {string} url - The URL of the API endpoint.
 * @param {object} options - The axios request configuration.
 * @param {number} maxRetries - Maximum number of retries.
 * @param {number} retryDelay - Delay between retries in milliseconds.
 * @returns {Promise} - A Promise that resolves to the response of the API call.
 */
const callApiWithRetry = async (
  url,
  options,
  maxRetries = 3,
  retryDelay = 1000
) => {
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      const response = await axios(url, options);

      return response; // Return the response on a successful request
    } catch (error) {
      if (attempt === maxRetries) {
        throw error; // Rethrow error after the last attempt
      }
      console.error(
        `Attempt ${attempt} failed. Retrying in ${retryDelay}ms...`
      );
      await new Promise((resolve) => setTimeout(resolve, retryDelay)); // Wait for retryDelay
    }
  }
};
