import React, { useState, useCallback } from "react";
import AddIcon from "@mui/icons-material/Add";
import CachedIcon from "@mui/icons-material/Cached";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Button, IconButton, Toolbar, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { FilterPage } from "./FilterPage";

const useStyles = makeStyles()((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftButtons: {},
  rightButtons: {},
  leftIcons: {
    "&:first-of-type": {
      marginLeft: -12,
    },
  },
  rightIcons: {
    padding: 12,
    marginTop: "-6px",
    width: 48,
    height: 48,
    "&:last-of-type": {
      marginRight: -12,
    },
  },
}));

const LabeledActionButton = ({
  instance,
  icon,
  onClick,
  label,
  enabled = () => true,
}) => (
  <Button
    variant="outlined"
    color="primary"
    onClick={onClick(instance)}
    disabled={!enabled(instance)}
  >
    {icon}
    &nbsp;
    {label}
  </Button>
);

const SmallIconActionButton = ({
  instance,
  icon,
  onClick,
  label,
  enabled = () => true,
  variant,
}) => {
  const { classes, cx } = useStyles();
  return (
    <Tooltip title={label} aria-label={label}>
      <span>
        <IconButton
          className={cx({
            [classes.rightIcons]: variant === "right",
            [classes.leftIcons]: variant === "left",
          })}
          onClick={onClick(instance)}
          disabled={!enabled(instance)}
          size="large"
        >
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );
};

const TableToolbar = ({
  instance,
  onAdd,
  onDelete,
  onEdit,
  extraCommands = [],
  onRefresh,
  onAddInUse,
  onUpdateInUse,
  onDeleteInUse,
}) => {
  const { columns } = instance;

  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  const hidableColumns = columns.filter(
    (column) => !(column.id === "_selector")
  );
  const handleFilterClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    setFilterOpen(true);
  }, []);

  function handleClose() {
    setColumnsOpen(false);
    setFilterOpen(false);
    setAnchorEl(undefined);
  }

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.leftButtons}>
        {onAdd && (
          <SmallIconActionButton
            instance={instance}
            icon={<AddIcon />}
            onClick={onAdd}
            label="Add"
            enabled={({ state }) =>
              Object.keys(state.selectedRowIds).length === 0 && !onAddInUse
            }
            variant="left"
          />
        )}
        {onEdit && (
          <SmallIconActionButton
            instance={instance}
            icon={<CreateIcon />}
            onClick={onEdit}
            label="Edit"
            enabled={({ state }) =>
              Object.keys(state.selectedRowIds).length === 1 && !onUpdateInUse
            }
            variant="left"
          />
        )}
        {onDelete && (
          <SmallIconActionButton
            instance={instance}
            icon={<DeleteIcon />}
            onClick={onDelete}
            label="Delete"
            enabled={({ state }) =>
              Object.keys(state.selectedRowIds).length > 0 && !onDeleteInUse
            }
            variant="left"
          />
        )}
        {extraCommands.map((c) => {
          const { type = "icon" } = c;
          return type === "icon" ? (
            <SmallIconActionButton
              key={`command-${c.label}`}
              instance={instance}
              icon={c.icon}
              onClick={c.onClick}
              label={c.label}
              enabled={c.enabled}
              variant="left"
            />
          ) : (
            <LabeledActionButton
              key={`command-${c.label}`}
              instance={instance}
              icon={c.icon}
              onClick={c.onClick}
              label={c.label}
              enabled={c.enabled}
            />
          );
        })}
      </div>
      <div className={classes.rightButtons}>
        {/* <ColumnHidePage
          instance={instance}
          onClose={handleClose}
          show={columnsOpen}
          anchorEl={anchorEl}
        /> */}
        <FilterPage
          instance={instance}
          onClose={handleClose}
          show={filterOpen}
          anchorEl={anchorEl}
        />
        {onRefresh && (
          <SmallIconActionButton
            instance={instance}
            icon={<CachedIcon />}
            onClick={() => onRefresh}
            label="Refresh Table"
            variant="right"
          />
        )}
        {/* {hideableColumns.length > 1 && (
          <SmallIconActionButton
            instance={instance}
            icon={<ViewColumnsIcon />}
            onClick={() => handleColumnsClick}
            label="Show / hide columns"
            variant="right"
          />
        )} */}
        <SmallIconActionButton
          instance={instance}
          icon={<FilterListIcon />}
          onClick={() => handleFilterClick}
          label="Filter by columns"
          variant="right"
        />
      </div>
    </Toolbar>
  );
};

export default TableToolbar;
