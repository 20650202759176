// import CustomTable from "./CustomTable";
import React, { useState, useEffect } from "react";
import { string, number } from "yup";
import MyTable from "../Table/MyTable";
import {
  createConfig,
  deleteConfig,
  getAllConfigs,
  updateConfig,
} from "../api/configService";
import { useMsal } from "@azure/msal-react";

const ConfigTable = () => {
  const [configData, setConfigData] = useState([]);
  const { instance } = useMsal();

  //   const editableColumnsConfig = {
  //     cost: {
  //       type: "input", // 'input' for regular text input
  //       //   options: ["Active", "Inactive", "Maintenance"], // Dropdown options
  //       onSave: async (rowIndex, columnId, newValue) => {
  //         const costUpdated = await handleCostChange(
  //           configData[rowIndex].vehicleType,
  //           newValue
  //         );
  //         console.log(costUpdated);
  //         if (!costUpdated) {
  //           return;
  //         }
  //         setConfigData((currentData) => {
  //           // Clone the current data array
  //           const updatedData = [...currentData];
  //           // Update the specific item
  //           if (updatedData[rowIndex] && columnId in updatedData[rowIndex]) {
  //             updatedData[rowIndex] = {
  //               ...updatedData[rowIndex],
  //               [columnId]: newValue, // Update the specific field
  //             };
  //           }
  //           return updatedData;
  //         });
  //       },
  //     },
  //     // You can add more editable columns here with similar configurations
  //   };

  //   const newRowConfig = {
  //     newRowDefaults: {
  //       vehicleType: "", // Default value for emailAddress
  //       cost: "", // Default value for anotherColumn
  //       // ... default values for other columns
  //     },
  //     onAddNewRow: async (newRowData) => {
  //       // Implement the logic to handle the addition of a new row
  //       // For example, updating the data array or making an API call
  //       // const user = await typeSchema.validate([]);
  //       // const user = await tableSchema.validate();
  //       console.log(newRowConfig);
  //     },
  //     columns: {
  //       vehicleType: {
  //         type: "dropdown",
  //         options: ["Option 1", "Option 2", "Option 3"],
  //       },
  //       cost: {
  //         type: "input",
  //       },
  //       // ... other column configurations
  //     },
  //   };

  const configColumns = React.useMemo(
    () => [
      {
        Header: "Body Type",
        accessor: "bodyType",
        canCreate: true,
        validationSchema: string().required(),
      },
      {
        Header: "Cost",
        accessor: "cost",
        editable: true,
        canCreate: true,
        validationSchema: number()
          .transform((value, originalValue) =>
            isNaN(value) && originalValue.trim() !== "" ? NaN : Number(value)
          )
          .typeError("You need to enter a number.")
          .required("This field is required.")
          .positive("The number must be positive.")
          .integer("The number must be an integer."),
      },
    ],
    []
  );

  useEffect(() => {
    // Fetch data on component mount
    const getAllVehicles = async () => {
      try {
        const response = await getAllConfigs(instance);
        if (response.data.success) {
          setConfigData(response.data.costLookupListModel);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getAllVehicles(instance);
  }, [instance]);

  const config = {
    onUpdate: async (row) => {
      try {
        // if (typeInUse(row.vehicleType)) {
        //   alert("Type is already in use");
        //   return;
        // }
        let response = await updateConfig(row.bodyType, row.cost, instance);
        if (!response.data.success) {
          alert(response.data.info);
          return;
        }
        setConfigData((prevConfigData) =>
          prevConfigData.map((item) =>
            item.bodyType === row.bodyType ? { ...item, ...row } : item
          )
        );
        alert(`Config: ${row.bodyType} ${row.cost} updated`);
      } catch (error) {
        alert("Failed to update config", error);
      }
    },
    onCreate: async (row) => {
      try {
        if (typeInUse(row.bodyType)) {
          alert("Type is already in use");
          return;
        }

        let response = await createConfig(row.bodyType, row.cost, instance);
        if (!response.data.success) {
          alert(response.data.info);
          return;
        }
        setConfigData((prevConfigData) => [...prevConfigData, row]);
        alert(`Config ${row.bodyType} ${row.cost} created`);
      } catch (error) {
        alert("Failed to create new config", error);
      }
    },
    onDelete: async (values) => {
      try {
        console.log(values);
        let response = await deleteConfig(values.bodyType, instance);
        if (!response.data.success) {
          alert(response.data.info);
          return;
        }
        setConfigData((prevConfigData) =>
          prevConfigData.filter(
            (currentRow) => currentRow.bodyType !== values.bodyType
          )
        );
        alert(`Config ${values.bodyType} deleted`);
      } catch (error) {
        alert("Failed to delete config", error);
      }
    },
  };

  function typeInUse(type) {
    return configData.some((currentRow) => currentRow.bodyType === type);
  }

  return (
    <MyTable data={configData} columns={configColumns} config={config} />
    // <div className="container">

    //   <div className="box justify-content-center align-items-center">
    //     <div className="formDiv">
    //       <div
    //         style={{
    //           display: "flex",
    //           flexDirection: "column",
    //           alignItems: "center",
    //         }}
    //       >
    //         <CustomTable
    //           data={configData}
    //           columns={configColumns}
    //           editableColumnsConfig={editableColumnsConfig}
    //           isTableEditable={true}
    //           newRowConfig={newRowConfig}
    //           onDeleteRow={(rowData) => {
    //             // Implement deletion logic
    //             // For example, remove the row from the data array or call an API
    //             console.log(rowData);
    //           }}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ConfigTable;
