import { useEffect, useState } from "react";

function usePaymentScript(sessionId) {
  const [scriptState, setScriptState] = useState({
    isScriptLoaded: false,
    isSessionConfigured: false,
    responseData: null,
    errors: null,
    isLoading: true,
  });
  useEffect(() => {
    const inputExists = document.getElementById("card-number") !== null;

    if (!inputExists) {
      return;
    }

    if (sessionId === "") {
      return;
    }

    try {
      window.PaymentSession.configure({
        session: sessionId,
        fields: {
          card: {
            number: "#card-number",
            securityCode: "#security-code",
            expiryMonth: "#expiry-month",
            expiryYear: "#expiry-year",
            nameOnCard: "#cardholder-name",
          },
        },
        frameEmbeddingMitigation: ["javascript"],
        callbacks: {
          initialized: function (response) {
            // console.log("initialized", response);

            // Handle initialization response
            const isSessionOk = response.status === "ok";
            setScriptState((prevState) => ({
              ...prevState,
              responseData: response,
              isSessionConfigured: isSessionOk,
              isScriptLoaded: true,
              errors: !isSessionOk
                ? { initError: response.message || "Initialization failed" }
                : null,
              isLoading: false,
            }));
          },
          formSessionUpdate: function (response) {
            // console.log("formSessionUpdate", response);

            // Handle response for update session
            let updateErrors = {
              cardNumber: null,
              expiryYear: null,
              expiryMonth: null,
              securityCode: null,
              name: null,
            };
            if (response.status === "fields_in_error") {
              Object.keys(updateErrors).forEach((field) => {
                updateErrors[field] = response.errors[field]
                  ? `${field
                      // Insert a space before each uppercase letter and capitalize the first letter
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())
                      .trim()} invalid or missing.`
                  : null;
              });
            }

            const cardInfo = response.sourceOfFunds?.provided?.card;
            if (cardInfo) {
              if (!cardInfo.securityCode) {
                updateErrors.securityCode = "Security code invalid or missing";
              }
              if (!cardInfo.nameOnCard) {
                updateErrors.name = "Name is invalid or missing";
              }
            }

            setScriptState((prevState) => ({
              ...prevState,
              responseData: response,
              isSessionConfigured: response.status === "ok",
              errors: updateErrors,
            }));
          },
          onerror: function (response) {
            console.log("onerror", response);
          },
        },
        interaction: {
          displayControl: {
            formatCard: "EMBOSSED",
            invalidFieldCharacters: "REJECT",
          },
        },
      });
    } catch (error) {
      setScriptState((prevState) => ({
        ...prevState,
        errors: { configError: error.message || "Configuration error" },
        isLoading: false,
      }));
    }
  }, [sessionId]); // Dependency array with the URL of the script

  return scriptState;
}

export default usePaymentScript;
