import { apiRequest } from "./apiUtils";

// Function to get a session id
export async function getCBASession(email, instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/PostVehiclePaymentSession`;
  const body = {
    emailAddress: email,
  };
  // const url = `http://localhost:5000/GetSession`;
  return await apiRequest(url, "POST", instance, body);
}

export async function validateCBAToken(sessionId, email, instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/PostVehiclePaymentInfo`;
  const body = {
    emailAddress: email,
    commBankSessionModel: {
      session: {
        id: sessionId,
      },
    },
  };
  return await apiRequest(url, "POST", instance, body);
}

export async function updatevalidateCBAToken(sessionId, email, instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/PutVehiclePaymentInfo`;
  const body = {
    emailAddress: email,
    commBankSessionModel: {
      session: {
        id: sessionId,
      },
    },
  };
  return await apiRequest(url, "PUT", instance, body);
}

export async function deletePaymentInfo(email, instance) {
  const url = `${process.env.REACT_APP_FUNCTION_URL}/DeleteVehiclePaymentInfo?emailAddress=${email}`;
  return await apiRequest(url, "DELETE", instance);
}
