const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_signupsignin",
    editProfile: "B2C_1_edit_profile",
    // other policies...
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://greensillfarmingweb.b2clogin.com/greensillfarmingweb.onmicrosoft.com/B2C_1_signupsignin",
    },
    // other authorities...
  },
  authorityDomain: "greensillfarmingweb.b2clogin.com",
};
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_FRONTEND_APP_REG_CLIENTID, // your client ID
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const appRoles = {
  CustomerUser: "customer",
  StaffUser: "staff",
};

export const loginRequest = {
  scopes: [
    "openid",
    "profile",
    process.env.REACT_APP_SCOPE, // Scope for Azure Function access
  ],
};
