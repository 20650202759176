import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Cleave from "cleave.js/react";
import { useMsal } from "@azure/msal-react";
import {
  getVehicleDetailsWithPlate,
  getAllVehicleDetails,
  updateVehicleDetailsRego,
} from "./api/vehicleService";
function LicensePlateInput(props) {
  const { instance } = useMsal();

  const [licensePlate, setLicensePlate] = useState("");
  const [savedLicensePlate, setSavedLicensePlate] = useState("");
  const [error, setError] = useState("");
  const currentAccount = instance.getActiveAccount();

  function getMatchingRegos(array, currentEmail) {
    return array
      .filter((object) => object.emailAddress === currentEmail)
      .map((object) => object.registrationNumber)
      .join(", "); // This will output a string
  }
  // Fetch license plate data on component mount
  useEffect(() => {
    const fetchLicensePlateData = async () => {
      try {
        const response = await getAllVehicleDetails(instance);
        if (!response.data.success) {
          alert("Please refresh the page");
        }
        if (response.data) {
          const licPlate = getMatchingRegos(
            response.data.vehicleModelList,
            currentAccount.username
          );
          if (licPlate === "") {
            setError("Please set your Registration number");
          } else if (licPlate.includes(",")) {
            setError(
              "Your account is set up with more than one vehicle. Please see staff to make changes."
            );
          } else {
            setError("");
          }
          setLicensePlate(licPlate);
          setSavedLicensePlate(licPlate);
          props.setRego(licPlate);
        }
      } catch (err) {
        setError("Please set your Registration number");
        console.error(err);
      }
    };

    fetchLicensePlateData();
  }, []);

  const handleInputChange = (e) => {
    setLicensePlate(e.target.value);
    if (error) setError("");
  };

  const getPlateDetails = async (plate) => {
    try {
      const response = await getVehicleDetailsWithPlate(plate, instance);
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicle details for plate:", plate, error);
      return {
        success: false,
        info: "Failed to fetch vehicle details. Please try again.",
      };
    }
  };

  const handleSave = async () => {
    try {
      let currentDetails = null;
      if (savedLicensePlate && savedLicensePlate !== licensePlate) {
        currentDetails = await getPlateDetails(savedLicensePlate);
      }

      let newDetails = await getPlateDetails(licensePlate);
      if (!newDetails.success) {
        setError(newDetails.info);
        return;
      }

      // Check for duplicate or claimed license plate
      if (newDetails.vehicleModel.emailAddress) {
        setError("License plate has already been claimed by another user.");
        return;
      }

      // Check if the new license plate is the same as the old one
      if (
        currentDetails &&
        newDetails.vehicleModel.registrationNumber ===
          currentDetails.vehicleModel.registrationNumber
      ) {
        setError(`License plate is already set to ${licensePlate}`);
        return;
      }

      // Update vehicle details
      await updateVehicleDetailsForPlate(
        newDetails.vehicleModel.registrationNumber,
        currentAccount.username
      );

      // Clear old details if it's not a new car
      if (currentDetails) {
        await updateVehicleDetailsForPlate(
          currentDetails.vehicleModel.registrationNumber,
          ""
        );
      }

      setSavedLicensePlate(licensePlate);
      props.setRego(licensePlate);
      setError("License plate has been saved.");
    } catch (error) {
      console.error(error);
      setError(`Error occurred while saving license plate: ${licensePlate}`);
    }
  };

  // Refactored update logic into a separate function
  const updateVehicleDetailsForPlate = async (registrationNumber, email) => {
    await updateVehicleDetailsRego(registrationNumber, email, "", instance);
  };

  return (
    <div className="container">
      <div className="box justify-content-center align-items-center">
        <div className="formDiv">
          Your vehicle's registration
          <Cleave
            placeholder="Registration number"
            // options={{ blocks: [10] }}
            onChange={handleInputChange}
            className="form-control"
            value={savedLicensePlate}
            disabled={licensePlate.includes(",")}
          />
          <Button
            onClick={handleSave}
            disabled={
              licensePlate === savedLicensePlate ||
              licensePlate.length === 0 ||
              licensePlate.includes(",")
            }
            className="w-100"
          >
            Save
          </Button>
          {error && <div style={{ color: "red" }}>{error}</div>}
        </div>
      </div>
    </div>
  );
}

export default LicensePlateInput;
