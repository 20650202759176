import { React, useEffect, useState } from "react";
// import { Route, Navigate } from "react-router-dom";
// import useAuth from "./azure/useAuth";
import { useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../compontents/azure/authConfig";
import { useNavigate } from "react-router-dom";
const ProtectedRoute = ({ ...props }) => {
  const { instance } = useMsal();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasShownUnauthorizedAlert, setHasShownUnauthorizedAlert] =
    useState(false);

  let currentAccount = instance.getActiveAccount();
  let navigate = useNavigate();

  useEffect(() => {
    // console.log(!hasShownUnauthorizedAlert, !isAuthorized, !isLoading);
    if (!hasShownUnauthorizedAlert && !isAuthorized && !isLoading) {
      alert("You are unauthorized to view this content");
      setHasShownUnauthorizedAlert(true); // Update state to ensure alert is shown only once
    }
  }, [isAuthorized, isLoading, hasShownUnauthorizedAlert]);
  const handlePopupBlocked = () => {
    alert(
      "Popup blocked! Please allow popups for this site and refresh the page."
    );
    // Optionally, provide a button or link for the user to retry authentication
  };
  const getAccessToken = async (instance, account) => {
    if (!account) {
      const popupWindow = await instance
        .acquireTokenPopup(loginRequest)
        .then(() => {
          currentAccount = instance.getActiveAccount();
        })
        .catch((error) => console.log(error));
      if (!popupWindow) {
        handlePopupBlocked();
        return;
      }
    }

    const silentRequest = {
      ...loginRequest,
      // scopes: [
      //   "https://GreensillFarmingWeb.onmicrosoft.com/1bac14bb-9127-46aa-89af-1591118e12a5/API.Access",
      // ],
      account: account,
    };

    // silentRequest.scopes.push(
    //   "https://GreensillFarmingWeb.onmicrosoft.com/1bac14bb-9127-46aa-89af-1591118e12a5/API.Access"
    // );
    return instance
      .acquireTokenSilent(silentRequest)
      .then((response) => {
        // console.log(response);
        // console.log(silentRequest);
        return response.idTokenClaims;
      })
      .catch((error) => {
        console.log("Failed to get user details");
      });
  };

  useEffect(() => {
    const onLoad = async () => {
      // setIsLoading(true); // Start loading

      if (currentAccount) {
        const userDetails = await getAccessToken(instance, currentAccount);
        const isStaff = userDetails["extension_isStaff"];
        let result;
        //TODO: Turn this code back on
        if (props.roles === "staff") {
          result = isStaff;
          if (!result) {
            // If not a staff member but trying to access a staff page, redirect to /customer
            setIsLoading(false);
            navigate("/");
          }
        } else if (props.roles === "customer") {
          result = !isStaff;
          if (!result) {
            // If not a customer but trying to access a customer page, redirect to /staff
            setIsLoading(false);
            navigate("/staff");
          }
        }
        // console.log(result);
        if (result === true) {
          setIsAuthorized(true);
          setIsLoading(false);
        }
      }
    };
    onLoad();
  }, [instance, currentAccount]);

  if (isLoading) {
    return (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <div className="data-area-div">Loading...</div>
      </MsalAuthenticationTemplate>
    ); // Loading indicator
  }
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      {isAuthorized ? (
        <div>{props.children}</div>
      ) : (
        <div className="data-area-div">Loading...</div>
      )}
    </MsalAuthenticationTemplate>
  );
};

export default ProtectedRoute;
