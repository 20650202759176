import { Popover, TextField, Button, Box } from "@mui/material";
import React, { useEffect, useState } from "react";

function EditPage({ row, isOpen, onClose, onSave, anchorEl }) {
  const [editedRow, setEditedRow] = useState(row.values);

  const handleChange = (columnId, value) => {
    setEditedRow((prev) => ({ ...prev, [columnId]: value }));
  };

  useEffect(() => {
    setEditedRow(row.values);
  }, [row]);
  //   console.log(row.cells[].column);
  if (Object.keys(row).length === 0) {
  } else {
    const editableCells = row.cells.filter(
      (cell) => cell.column.id !== "_selector"
    );
    return (
      <Popover
        open={isOpen}
        onClose={onClose}
        anchorEl={anchorEl}
        sx={{ p: 2 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {editedRow &&
            editableCells.map((cell) => (
              <TextField
                key={cell.column.id}
                label={cell.column.id}
                value={editedRow[cell.column.id] || ""}
                onChange={(e) => handleChange(cell.column.id, e.target.value)}
                variant="outlined"
                fullWidth
                disabled={!cell.column.editable} // Check if the column is editable
              />
            ))}
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSave(editedRow)}
          >
            Save
          </Button>
        </Box>
      </Popover>
    );
  }
}

export default EditPage;
